import 'core-js/stable'
import {createApp} from 'vue'
import App from './App'
import router from './router'
import axios from "axios";
import store from './store';
import VueTheMask from 'vue-the-mask';
// import Jquery from 'vue-jquery';
// import VueCompositionAPI from '@vue/composition-api';
import feather from 'feather-icons';
import a from 'admin-lte/dist/js/adminlte';
import b from 'admin-lte/plugins/bootstrap/js/bootstrap.bundle';

import vueMask from 'vue-jquery-mask';
import VueToast from 'vue-toast-notification';
// import bootstatp from 'bootstrap-vue';
import Vuex from "vuex";

axios.defaults.withCredentials = false
// axios.defaults.baseURL = 'http://api.local/api/';
axios.defaults.baseURL = '//apilauricio.cut.ninja/api/';

axios.interceptors.request.use(function (config) {
    const token = store.state.accessToken
    config.headers.Authorization = 'Bearer ' + token;
    return config;
});


createApp(App)
    .use(VueTheMask)
    .use(b)
    .use(a)
    // .use(Jquery)
    .use(vueMask)
    // .use(bootstatp)
    // .use(VueCompositionAPI)
    .use(VueToast, {})
    .use(feather)
    .use(store)
    .use(router)
    // .use(axios)
    .use(Vuex)
    .mount('#app')


axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.config.url === 'login' && error.response.status === 401) {
        return error.response
    }

    if (error.response.status === 401) {
        //place your reentry code
        localStorage.removeItem('accessToken');
        localStorage.removeItem('acesso');
        window.location.href = '/login'
    }

    return error.response;
});

// prototype.$log = console.log.bind(console)
//
// new Vue({
//     el: '#app',
//     router,
//     store,
//     axios,
//     template: '<App/>',
//     components: {
//         App
//     }
// })
