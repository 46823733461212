import Vuex from 'vuex'
import axios from 'axios'
import router from './router';


const state = {
    sidebarShow: 'responsive',
    sidebarMinimize: false,
    accessToken: null,
    loggingIn: false,
    loginError: null
}

const actions = {
    doLogin({ commit }, loginData) {
        commit('loginStart');

        axios.post('login', {
            ...loginData
        })
            .then(response => {
                if(response.status === 401){
                    commit('loginStop', 'Dados Inválidos!');
                    commit('updateAccessToken', null);
                } else {
                    localStorage.setItem('accessToken', response.data.access_token);
                    localStorage.setItem('acesso', response.data.user.acesso);
                    commit('loginStop', null);
                    commit('updateAccessToken', response.data.access_token);
                    router.push('/dashboard');
                }
            })
            .catch(error => {
                commit('loginStop', error.response.data.error);
                commit('updateAccessToken', null);
            })
    },
    fetchAccessToken({ commit }) {
        commit('updateAccessToken', localStorage.getItem('accessToken'));
    },
    logout({ commit }) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('acesso');
        commit('logout');
        router.push('/login');
    }
}

const mutations = {
    toggleSidebarDesktop (state) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile (state) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set (state, [variable, value]) {
        state[variable] = value
    },
    loginStart: state => state.loggingIn = true,
    loginStop: (state, errorMessage) => {
        state.loggingIn = false;
        state.loginError = errorMessage;
    },
    updateAccessToken: (state, accessToken) => {
        state.accessToken = accessToken;
    },
    logout: (state) => {
        state.accessToken = null;
    }
}

export default new Vuex.Store({
    state,
    mutations,
    actions,
})