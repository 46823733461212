<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>

<style>
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-Regular"),
  url(assets/fonts/Montserrat-Regular.ttf) format("truetype");
}
</style>