// import Vue from 'vue'
import {createRouter, createWebHistory} from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

// Views - Pages
// const Page404 = () => import('@/views/pages/Page404')
// const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// Vue.use(Router)
import store from '../store';

const ObraForm = () => import("@/views/pages/obras/ObraForm");
const ObraList = () => import("@/views/pages/obras/ObraList");
const Orcamento = () => import("@/views/pages/obras/Orcamento");
const ObraOrcamento = () => import("@/views/pages/obras/ObraOrcamento");
const NotaFiscalInsumo = () => import("@/views/pages/NotaFiscalInsumo/NotaFiscalInsumo");
const NotaFiscalForm = () => import("@/views/pages/NotaFiscalInsumo/NotaFiscalForm");
const BuySingleForm = () => import("@/views/pages/NotaFiscalInsumo/SingleForm");
const ReceitaObra = () => import("@/views/pages/receita/ReceitaObra");
const ReceitaFormObra = () => import("@/views/pages/receita/ReceitaForm");
const SingleFormReceita = () => import("@/views/pages/receita/SingleForm");
const ClienteForm = () => import('@/views/pages/cliente/ClienteForm');
const ClienteList = () => import("@/views/pages/cliente/ClienteList");
const FornecedorList = () => import("@/views/pages/Fornecedor/FornecedorList");
const FornececdorForm = () => import("@/views/pages/Fornecedor/FornecedorForm");

const UsuarioForm = () => import('@/views/pages/usuario/UsuarioForm');
const UsuarioList = () => import('@/views/pages/usuario/UsuarioList');

const InsumoList = () => import('@/views/pages/insumo/InsumoList');
const InsumoForm = () => import('@/views/pages/insumo/InsumoForm');
const Relatorio = () => import('@/views/pages/relatorio/relatorio')



const routes = [
        {
            path: '/login',
            name: 'Login',
            meta: {
                private: false
            },
            component: Login
        },
        {
            path: '/register',
            name: 'Register',
            meta: {
                private: false
            },
            component: Register
        },

        {
            path: '/',
            redirect: '/dashboard',
            name: 'Home',
            meta: {
                private: true
            },
            component: TheContainer,
            children: [
                {
                    path: 'dashboard',
                    name: 'Dashboard',
                    meta: {
                        private: true
                    },
                    component: Dashboard
                },
                // Cliente
                {
                    path: 'cliente',
                    meta: {
                        private: true
                    },
                    component: {
                        template: `<router-view></router-view>`
                    },
                    children: [
                        {
                            path: 'lista',
                            name: 'Listagem CLiente',
                            meta: {
                                private: true
                            },
                            component: ClienteList
                        },
                        {
                            path: 'form',
                            name: 'Cadastro de Cliente',
                            meta: {
                                private: true
                            },
                            component: ClienteForm
                        },
                        {
                            path: 'form/:id',
                            props: {
                                isEdit: true
                            },
                            name: 'Edição de Cliente',
                            meta: {
                                private: true
                            },
                            component: ClienteForm
                        }
                    ]
                },
                // usuario
                {
                    path: 'usuario',
                    component: {
                        template: `<router-view></router-view>`
                    },
                    children: [
                        {
                            path: 'lista',
                            name: 'Listagem de Usuário',
                            meta: {
                                private: true
                            },
                            component: UsuarioList
                        },
                        {
                            path: 'form',
                            name: 'Cadastro de Usuário',
                            meta: {
                                private: true
                            },
                            component: UsuarioForm
                        },
                        {
                            path: 'form/:id',
                            props: {
                                isEdit: true
                            },
                            name: 'Edição de Usuário',
                            meta: {
                                private: true
                            },
                            component: UsuarioForm
                        }
                    ]
                },

                // Obra
                {
                    path: 'obra',
                    component: {
                        template: `<router-view></router-view>`
                    },
                    children: [
                        {
                            path: 'lista',
                            name: 'Listagem Obra',
                            meta: {
                                private: true
                            },
                            component: ObraList
                        },
                        {
                            path: 'form',
                            name: 'Cadastro de Obra',
                            meta: {
                                private: true
                            },
                            component: ObraForm
                        },
                        {
                            path: 'form/:id',
                            props: {
                                isEdit: true
                            },
                            name: 'Edição de Obra',
                            meta: {
                                private: true
                            },
                            component: ObraForm
                        }
                    ]
                },
                // fornecedores
                {
                    path: 'fornecedor',
                    component: {
                        template: `<router-view></router-view>`
                    },
                    children: [
                        {
                            path: 'lista',
                            name: 'Listagem',
                            meta: {
                                private: true
                            },
                            component: FornecedorList
                        },
                        {
                            path: 'form',
                            name: 'Cadastro de Fornecedor',
                            meta: {
                                private: true
                            },
                            component: FornececdorForm
                        },
                        {
                            path: 'form/:id',
                            props: {
                                isEdit: true
                            },
                            name: 'Edição de Fornecedor',
                            meta: {
                                private: true
                            },
                            component: FornececdorForm
                        }
                    ]
                },
                //  gerenciamento
                {
                    path: 'orcamento',
                    component: {
                        template: `<router-view></router-view>`
                    },
                    children: [
                        {
                            path: 'lista',
                            name: 'Orcamento',
                            meta: {
                                private: true
                            },
                            component: Orcamento
                        },
                        {
                            path: 'lista/:id',
                            name: 'ObraOrcamento',
                            meta: {
                                private: true
                            },
                            component: ObraOrcamento
                        },
                        {
                            path: 'notafiscal/:id',
                            name: 'Nota Fiscal Insumo',
                            meta: {
                                private: true
                            },
                            component: NotaFiscalInsumo
                        },
                        {
                            path: 'notafiscal/compra/obra/:id',
                            name: 'Notal Fiscal Lançamentos',
                            meta: {
                                private: true
                            },
                            component: NotaFiscalForm
                        },
                        {
                            path: 'notafiscal/compra/:id',
                            name: 'Nota Fiscal Edit',
                            meta: {
                                private: true
                            },
                            props: {
                                isEdit: true
                            },
                            component: BuySingleForm
                        },
                        {
                            path: 'receita/:id',
                            name: 'Lançamento de Reita',
                            meta: {
                                private: true
                            },
                            component: ReceitaObra
                        },
                        {
                            path: 'receita/lancamento/obra/:id',
                            name: 'Lançamento de Reita Lançamento',
                            meta: {
                                private: true
                            },
                            component: ReceitaFormObra
                        },
                        {
                            path: 'receita/lancamento/:id',
                            name: 'Lançamento de Reita  Edição',
                            meta: {
                                private: true
                            },
                            component: SingleFormReceita
                        }
                    ]
                },
                // Insumos
                {
                    path: 'insumo',
                    component: {
                        template: `<router-view></router-view>`
                    },
                    children: [
                        {
                            path: 'lista',
                            name: 'Listagem Insumos',
                            meta: {
                                private: true
                            },
                            component: InsumoList
                        },
                        {
                            path: 'form',
                            name: 'Cadastro de Insumo',
                            meta: {
                                private: true
                            },
                            component: InsumoForm
                        },
                        {
                            path: 'form/:id',
                            props: {
                                isEdit: true
                            },
                            name: 'Edição de Insumo',
                            meta: {
                                private: true
                            },
                            component: InsumoForm
                        }
                    ]
                },
                // relatorio
                {
                    path: '/',
                    component: {
                        template: `<router-view></router-view>`
                    },
                    children: [
                        {
                            path: 'relatorio',
                            name: 'Relatorio',
                            meta: {
                                private: true
                            },
                            component: Relatorio
                        }
                    ]
                }
            ]
        },
    ];



let router = createRouter({
    history: createWebHistory(process.env.BASE_URL), // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior: () => ({y: 0}),
    routes: routes
})

router.beforeEach((to, from, next) => {
    store.dispatch('fetchAccessToken');

    if (to.fullPath === '/' ) {
        if (!store.state.accessToken) {
            next('/login');
            return;
        }
    }

    if (to.meta.private === true) {
        if (!store.state.accessToken) {
            next('/login');
            return;
        }
    }

    if (to.fullPath === '/login' ) {
        if (store.state.accessToken) {
            next('/');
            return;
        }
    }
    next();
    return;
});
export default router;

